import React, { useState } from "react";
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from "../components/seo"
import PrimaryTags from "../components/Index/primaryTags"
import FeedSection from "../components/Index/feedSection"
import PeopleBlock from "../components/Index/peopleBlock";
import ReduxWrapper from "../redux/reduxWrapper";

const TagTemplate = ({ data, pageContext }) => {
  const tag = pageContext.tag;

  const seoTitle = `${tag} stories: news, blogs, & more`;
  const seoDescription = `${tag} news, updated daily: articles, podcasts, & more from leading publishers & influencers. Free ${tag} resources & information`;

  const [updateLoginModal, setUpdateLoginModal] = useState(true)

  return (
    <Layout
      updateLoginModal={updateLoginModal}
      setUpdateLoginModal={setUpdateLoginModal}
    >
      <Seo title={seoTitle} description={seoDescription} />
      <PrimaryTags selectedTag={tag} primaryTags={data.allPrimaryTags.nodes} />
      <PeopleBlock />
      <FeedSection selectedTag={tag} feedStories={data.allFeedStories.nodes} />
    </Layout>
  );
};

const WrappedPage = (props) => (
    <ReduxWrapper element={<TagTemplate {...props} />} />
)
export default WrappedPage;

export const feedStoryQuery = graphql`
query($tag: String!) {
  allFeedStories (filter: {tags: {eq: $tag}}) {
    nodes {
      id
      description
      exerpt
      url
      primaryTag
      tags
      title
      createdAt
      updatedAt
      lastModifiedDate
      media {
        url
        type
        old_url
        feedMediaType
        title
        length
        nodeName
        old_url
        type
      } 
      feedSource {
        id
        type
        sourceUrl
        favicon
        primaryTag
        createdAt
        updatedAt
        user {
          email
          username
          roles
        }
      }
    }
  }
  allPrimaryTags {
    nodes {
      label
    }
  }
}
`;
